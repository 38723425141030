import apiJwtConfig from './jwtConfig'

function generateFilter(paramsObj) {
  // create a string with filter params for api calls
  let index = 0
  let filterStr = ''

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(paramsObj)) {
    if (index === 0) {
      filterStr += '?'
    } else if (index !== paramsObj.length) {
      filterStr += '&'
    }
    filterStr += `${key}=${value}`
    index += 1 // increment index
  }
  return filterStr
}

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...apiJwtConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns) {
    this.axiosIns = axiosIns

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        /* if (process.env.NODE_ENV === 'development') {
          // use development auth token
          accessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzNSIsImp0aSI6ImU2YTNkNDc3YzRjNmE0MzJhN2VlMDA4ZDVmZWRiZDE0ZTE5OGQzNDQxN2E2ODZjMGE3YzRkNzE1ZjhmOTFhODU2ZmRiZjk2MDNhNWZhMzU5IiwiaWF0IjoxNjYzMDg5NDE5LjE3Nzk5OCwibmJmIjoxNjYzMDg5NDE5LjE3ODAwNiwiZXhwIjoxNjk0NjI1NDE5LjE2NjM1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.FfnBxl9wzUp_RZ2LNkH1qgXGbhcHFHYINi4yAvwVVoc-5rdG8wzfgYruWOJ7yDC5zNWbakuvO1toWRsnVlA2y8HWByWPlbRdgg83sNrSBKKsYVwnv1xwtiXZ2cls-PGxg6wCsWwGTx-U2aIWSScBqu3Uvrduikc3eHMAJjuOeaBFfjBRBZl3TEQ4PiYCsM52OkmV3FBPBfpD4IUUg2h8K4aNOZIaK68LvBK-p38-o9jI4JFuhs_MfAuV7mBOnYcoHcW0V5xi3CCQXO82NUZY1F0n3dvGQnTzGxh_Aqzb9YaXi8p_V5WjfrQLrLAeZwCiH7iyEmnPkyPiT1vARgV19b_0m96iWBW6TczQTwH42vozz4SKkaIJ-RTr1qYUBrxKaHUTdBHGiKcM7GmUs6xcmT4KtzmU8J_jWcN2WLAjtXMZL1ibuYCUrM3Ryz6rgD34Oge9kq0kQnDjDVtdeJzwGEdDBiZFrNqlNqO4tfBbOu1cmUJ8ZPXcs0PfCv-eU7120PX7BeAMCjuJlpcDYLaXnHLUO_iIRUZr7R7WUVzGCDZla9jBNORJSKmIG1zNnyMA14nsDM-zovuOGmgDxgydgzTn0v_6YFSLRmcs6UtmvZWgTiuPBjiGbKJvX_M9q2LVMxKAyxup9Ptyynf5QHc49lzXJB8T_qwX7Ws8vMtkGUE'
        } */

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        // set base url
        config.baseURL = this.jwtConfig.baseUrl // eslint-disable-line no-param-reassign

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        /* const { config, response: { status } } = error */
        const { response } = error
        /* const originalRequest = config */

        // if (status === 401) {
        if (response && response.status === 401) {
          console.log('useJwt 401 Unauthorized', response)

          // Clear localstorage
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('packagedUserData')
          localStorage.removeItem('companyData')
          localStorage.removeItem('userData')
          localStorage.removeItem('supportData')

          // Redirect to login page
          window.location.href = '/' // '/login'

          /* if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest */
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  /* AUTHENTICATION */

  login(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.login}`, ...args)
  }

  register(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.register}`, ...args)
  }

  getLoginUrl() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleAuth}/login/url`)
  }

  hookupGoogleAuth(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.googleAuth}/hookup`, ...args)
  }

  deleteGoogleAuth(uuid, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.googleads}/user/${uuid}`, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.refresh}`, {
      refreshToken: this.getRefreshToken(),
    })
  }

  verifyEmail(verificationCode, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.session}/email/verify/${verificationCode}`, ...args)
  }

  resendVerificationCode(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.session}/email/verification/resend`, ...args)
  }

  recoverPassword(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.session}/reset/start`, ...args)
  }

  completeRecoverPassword(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.session}/reset/complete`, ...args)
  }

  /* CONVERSIONS */

  setConversionValue(domainId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.domain}/${domainId}/conversionvalues`, ...args)
  }

  getConversionDigest(...args) {
    const conversionArgs = { page: 1, size: 10, ...args[0] }
    const filterParams = generateFilter(conversionArgs)
    return this.axiosIns.get(`${this.jwtConfig.endpoints.conversions}/digest/v3${filterParams}`)
  }

  getVerifiedConversionDigest(...args) {
    const conversionArgs = { page: 1, size: 10, ...args[0] }
    const filterParams = generateFilter(conversionArgs)
    return this.axiosIns.get(`${this.jwtConfig.endpoints.conversions}/digest/verified${filterParams}`)
  }

  getConversion(conversionId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.conversions}/${conversionId}`)
  }

  getConversionTransactions(conversionId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.conversions}/${conversionId}/transactions`)
  }

  setConversionVerified(conversionId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.conversions}/${conversionId}/approve`, ...args)
  }

  setConversionWon(conversionId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.conversions}/${conversionId}/won`, ...args)
  }

  setConversionPending(conversionId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.conversions}/${conversionId}/pending`, ...args)
  }

  setConversionLost(conversionId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.conversions}/${conversionId}/lost`, ...args)
  }

  setConversionRejected(conversionId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.conversions}/${conversionId}/reject`, ...args)
  }

  setConversionSpam(conversionId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.conversions}/${conversionId}/spam`, ...args)
  }

  setConversionFlagged(conversionId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.conversions}/${conversionId}/flag`, ...args)
  }

  getReportConversionSpam(...args) {
    const conversionArgs = { ...args[0] }
    const filterParams = generateFilter(conversionArgs)
    return this.axiosIns.get(`${this.jwtConfig.endpoints.reports}/conversions/spam/csv${filterParams}`)
  }

  /* CONVERSION FORMS */

  getConversionForm(conversionId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.conversions}/${conversionId}/form/data`)
  }

  getTargetKeys() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.forms}/targetkeys`)
  }

  getRemapMenu(conversionId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.forms}/conversions/${conversionId}`)
  }

  postFormRemap(conversionId, historicalStatus, ...args) {
    const conversionArgs = { historical: historicalStatus }
    const filterParams = generateFilter(conversionArgs)
    return this.axiosIns.post(`${this.jwtConfig.endpoints.forms}/conversions/${conversionId}/${filterParams}`, ...args)
  }

  getIgnoredForms() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.forms}/ignore`)
  }

  ignoreFormRemap(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.forms}/ignore`, ...args)
  }

  deleteFormRemap(formId, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.forms}/ignore/${formId}`, ...args)
  }

  /* DASHBOARD */

  getConversionsStatusChartData(domain, startDate, endDate) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.dashboard}/conversions/status/pie?domain=${domain}&start=${startDate}&end=${endDate}`)
  }

  getConversionsStatusBarChartData(status, domain, startDate, endDate) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.dashboard}/conversions/status/${status}/bar?domain=${domain}&start=${startDate}&end=${endDate}`)
  }

  getConversionsStatusLineChartData(status, domain, startDate, endDate) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.dashboard}/conversions/status/${status}/time/chart?domain=${domain}&start=${startDate}&end=${endDate}`)
  }

  getConversionsBarChartData(domain, startDate, endDate) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.dashboard}/conversions/time/bar?domain=${domain}&start=${startDate}&end=${endDate}`)
  }

  getConversionsBarChartByDayData(status, domain, startDate, endDate) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.dashboard}/conversions/day/bar?domain=${domain}&start=${startDate}&end=${endDate}`)
  }

  /* GOOGLE ADS */

  getAdCustomers() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleads}/customers`)
  }

  getAdCampaigns(customerId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleads}/customers/${customerId}/campaigns`)
  }

  getConversionAction(conversionId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleads}/conversions/${conversionId}/conversionaction`)
  }

  getConversionActionFromCustomer(customerId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleads}/customers/${customerId}/conversionaction`)
  }

  getActionsCategories() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleads}/conversionaction/categories`)
  }

  getActionsTypes() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleads}/conversionaction/types`)
  }

  getActionsStatuses() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.googleads}/conversionaction/statuses`)
  }

  /* TWILIO */

  getAssignedNumbers() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.twilioNumbers}/incoming`)
  }

  deleteAssignedNumber(phoneNumber) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.twilioNumbers}/${phoneNumber}`)
  }

  getDestinationNumbers() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.twilioNumbers}/destination`)
  }

  setDestinationNumber(phoneNumber, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.twilioNumbers}/destination/${phoneNumber}`, ...args)
  }

  deleteDestinationNumber(phoneNumber, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.twilioNumbers}/destination/${phoneNumber}`, ...args)
  }

  getAvailableLocalNumbers(...args) {
    const queryArgs = { ...args[0] }
    const filterParams = generateFilter(queryArgs)
    return this.axiosIns.get(`${this.jwtConfig.endpoints.twilioNumbers}/available/local${filterParams}`)
  }

  setLocalNumber(phoneNumber, destinationId, locality, ...args) {
    const queryString = `?${new URLSearchParams(locality).toString()}`
    return this.axiosIns.post(`${this.jwtConfig.endpoints.twilioNumbers}/${phoneNumber}/local/destination/${destinationId}${queryString}`, ...args)
  }

  /* SALES */

  getSalesEmails() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/salesemails`)
  }

  deleteSalesEmail(emailId, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.users}/me/salesemails/${emailId}`, ...args)
  }

  /* BILLING */

  getBillingLevels() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.billing}/levels`)
  }

  getBillingPercentages() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.billing}/percentages`)
  }

  getBillingOverages() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.billing}/overages`)
  }

  getCreditCard(clientId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.clients}/${clientId}/creditcard`)
  }

  getInvoiceToDate(clientId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.billing}/agency/clients/${clientId}/todate`)
  }

  getAllInvoices(clientId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/agency/clients/${clientId}/invoices/digest`)
  }

  getInvoice(invoiceId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/agency/clients/invoices/${invoiceId}`)
  }

  getNextBillingData(clientId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.billing}/agency/clients/${clientId}/level`)
  }

  setBillingAddress(clientId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me/agency/clients/${clientId}/billingaddress`, ...args)
  }

  setCreditCard(clientId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.clients}/${clientId}/creditcard`, ...args)
  }

  /* USERS */

  getCurrentUser() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me`)
  }

  updateCurrentUser(...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me`, ...args)
  }

  getCurrencies(customerId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.conversions}/${customerId}/currencies/all`)
  }

  updateDefaultCurrency(currency, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me/currency/${currency}`, ...args)
  }

  addUserDomain(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/me/agency/clients/domain`, ...args)
  }

  deleteUserDomain(domainId, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.users}/me/agency/clients/domain/${domainId}`, ...args)
  }

  getAgencyClients() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/agency/clients`)
  }

  addAgencyClient(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/me/agency/clients`, ...args)
  }

  updateAgencyCustomer(...args) {
    const customerData = args[0]
    return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me/agency/clients/${customerData.agency_id}/ggclient/${customerData.google_customer_id}`, ...args)
  }

  verifyAgencyCustomer(...args) {
    const customerData = args[0]
    return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/me/agency/clients/${customerData.agency_id}/ggclient/${customerData.google_customer_id}/preflight`, ...args)
  }

  getAgencyClientUsers(agencyId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/agency/clients/${agencyId}/users`)
  }

  createUserInvite(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/invite/create`, ...args)
  }

  acceptUserInvite(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/invite/accept`, ...args)
  }

  postSyncGoogleCustomers() {
    return this.axiosIns.post('/api/reset/customers/users/me')
  }
}
